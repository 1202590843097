:root {
    --mainColor: #fff;
}

h1, h2 {
    font-size: 75px;
    text-transform: uppercase;
}

h1 span, h2 span {
    width: 100%;
    float: left;
    font-size: 40px;
    -webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 80%);
    clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 80%);
    transform: translateY(-50px);
    opacity: 0;
    text-align: center;
    animation-name: titleAnimation;
    animation-timing-function: ease;
    animation-duration: 3s;
    padding-bottom: 0.5em;
    margin: 15px 5px;
    transition: all 0.5s ease-in-out;
    background: linear-gradient(to bottom, var(--mainColor) 0%, var(--mainColor) 100%);
    background-position: 0 100%;
    background-repeat: repeat-x;
    background-size: 2px 2px;
    color: #fff;
    text-decoration: none;
}

h1 span:hover, h2 span:hover {
    text-decoration: underline;
}

h1 span {
    animation-delay: 0.6s;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
}

h1 span:first-child {
    animation-delay: 0.7s;
}

h1 span:last-child {
    color: #fff;
    animation-delay: 0.5s;
}

h2 {
    top: 0;
    position: absolute;
}

h2 span {
    animation-delay: 4.1s;
    -webkit-animation-fill-mode: forwards;
}

h2 span:first-child {
    animation-delay: 4.2s;
}

h2 span:last-child {
    color: #ffe221;
    animation-delay: 4s;
}

h1 span:hover {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg id='squiggle-link' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:ev='http://www.w3.org/2001/xml-events' viewBox='0 0 20 4'%3E%3Cstyle type='text/css'%3E.squiggle{animation:shift .3s linear infinite;}@keyframes shift {from {transform:translateX(0);}to {transform:translateX(-20px);}}%3C/style%3E%3Cpath fill='none' stroke='%23ffffff' stroke-width='2' class='squiggle' d='M0,3.5 c 5,0,5,-3,10,-3 s 5,3,10,3 c 5,0,5,-3,10,-3 s 5,3,10,3'/%3E%3C/svg%3E");
  background-position: 0 100%;
  background-size: auto 6px;
  background-repeat: repeat-x;
  text-decoration: none;
}

@keyframes titleAnimation {
    0% {
        transform: translateY(-50px);
        opacity: 0;
        -webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 80%);
        clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 80%);
    }
    100% {
        transform: translateY(0);
        opacity: 1;
        -webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 0%);
        clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 0%);
    }
}